.projects-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin-bottom: 20px;
}

.project-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  transition: opacity 0.3s;
}

.project-item:hover {
  opacity: 0.7;
}

.project-img {
  max-width: 100%;
  max-height: 200px;
  width: auto;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-description {
  margin-top: 10px;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .project-item {
    width: 100%; 
    margin: 0 auto;
  }

  .project-details {
    position: relative;
    width: 100%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.9);
    margin-top: 10px; /* Adjust as needed */
    padding: 10px;
  }
}

/* Tablet and Larger Screens */
@media (min-width: 601px) {
  .projects-mobile {
    flex-direction: column;
    justify-content: space-around;
  }

  .project-item {
    width: 100%;
  }

  .project-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.project-item.selected {
  opacity: 0.7;
}

.button-container {
  margin-top: 10px; /* Adjust as needed */
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

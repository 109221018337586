/* SplashPage.css */

html {
  scroll-behavior: smooth;
  font-size: 20px;
}

.splash-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
  color: #ffffff;
}

.background-animation {
  position: absolute;
  width: 100%;
  height: 40%;
  background: linear-gradient(-45deg, #3498db, #2980b9, #2c3e50, #34495e);
  background-size: 500% 500%;
  animation: gradientAnimation 15s ease infinite;
}

@media (max-width: 600px) {
  .background-animation {
    height: 52%;
  }
}

.content {
  z-index: 1; /* Ensure content is above the background animation */
  text-align: center;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* SplashPage.css */

.splash-page .name {
  font-size: 4em;
  margin-bottom: 10px;
  font-weight: 700;
}

.splash-page p {
  font-size: 1.5em;
  margin-bottom: 20px;
  font-weight: 500;
}

.splash-page .profession {
  color: aliceblue;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.splash-page a {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2em;
  text-decoration: none;
  color: #ffffff;
  background-color: #1f2c39;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.splash-page a:hover {
  background-color: #2980b9;
}

/* ... Your existing styles for background-animation, splash-page, etc. ... */

#carousel-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  perspective: 1000px;
  display: flex;
}

.project-card {
  width: 300px;
  height: 200px;
  margin: 0 20px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  cursor: pointer;
}

.project-card:hover {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
}

.card-front {
  background-color: #3498db;
  color: #fff;
  border-radius: 15px; /* Added border-radius for rounded edges */
}

.card-back {
  background-color: aliceblue;
  color: #333333;
  border-radius: 15px; /* Added border-radius for rounded edges */
  transform: rotateY(180deg);
}

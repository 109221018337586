body {
  margin: 0;
  font-size: 16px;
  background-color:aliceblue;
  font-family: 'monospace';
  font-family: 'monospace';
  position: relative;
  z-index: -1;
}


body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(45deg, #3498db, aliceblue);
  background-size: 500% 500%;
  animation: complementaryAnimation 10s ease infinite;
}

@keyframes complementaryAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.app {
  text-align: center;
}

.carousel-img {
  width: 600px;
  justify-self: center;
}

.projects-div {
  margin-top: 10%;
  width: 80%;
  position: relative;
  left: 10%;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 2;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #3498db; 
  border: 2px solid #2980b9; 
  border-radius: 50%;
  color: aliceblue;
}


.carousel-control-prev:hover,
.carousel-control-next:hover {
  background-color: #2c3e50; 
}

#exp-div{
  color:aliceblue;
  padding: 0;
  border-top-style:outset;
  border-top-width: 2px;
  border-top-color:#2c3e50;
  margin: 30% 15% 20px;
  padding: 5%;
  background-color: #2c3e50;
  height: auto;
  border-radius: 75px;
}

/*about section */
.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  padding: 0 10% 0;
  color: #333333;
}

.photo-container {
  width: 100%; 
  max-width: 300px; 
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 20px;
}

.photo-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  max-width: 80%;
  text-align: center;
  font-size: large;
  color: #333333;
}

.email-div {
  margin: 0 38% 20px;
  padding: 20px; 
  border-radius: 25px;
  background-color: aliceblue;
}

.email-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: aliceblue;
  text-decoration: none;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.email-btn:hover {
  background-color: #2980b9;
}

.email-btn::after {
  content: '\2192'; 
  margin-left: 8px;
}


.self-contained-quote{
  background-color: aliceblue;
  border-radius: 25px;
  padding: 5px;
  margin: 20px 30% 50px;
}



@media only screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }

  .company-name, .job-title, .job-description {
    text-align: center;
}

  #exp-div{
    margin: 0;
    border-radius: 0;
    padding: 0 0 10px 0;
    height: fit-content;
  }

  .about-section {
    margin: 10px;
  }


  .text-container {
    padding: 0 5px;
  }
  .email-btn {
    display: block;
    width: 100%;
  }


  .projects-div {
    margin-top: 5%; 
    width: 90%; 
    left: 5%;
  }

  .self-contained-quote{
    margin: 20px 0 50px;
  }

  .email-div {
    margin: 0 10% 20px;
  }

}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  body {
    font-size: 12px;
  }

  .projects-div {
    width: 100%; 
    position: relative;
    left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: auto;
  }

  .carousel-img {
    width: 100%;
  }

  .about-section {
    margin: 15px;
  }

  .photo-container {
    width: 180px;
    height: 180px;
  }

  .email-div {
    margin: 0 0 20px;
  }
}

.company-name {
  color: #336699;
}

.job-title {
  color: #336699;
  margin-top: 10px;
}

.job-description {
  margin-top: 10px;
  line-height: 1.5;
}


.section.top {
  opacity:0;
  transform: translateX(0);
  transition: opacity 0.8s, transform 0.8s;
  margin: 0 10% 20px;
}

.section.middle {
  opacity: 0;
  transform: translateX(0);
  transition: opacity 0.8s, transform 0.8s;
  margin: 0 10% 20px;
}

.section.bottom {
  opacity: 0;
  transform: translateX(0);
  transition: opacity 0.8s, transform 0.8s;
  margin: 0 10% 20px;
}

.section.top.slide-in {
  opacity: 1;
  transform: translateX(0);
}

.section.middle.slide-in {
  opacity: 1;
  transform: translateX(0);
}

.section.bottom.slide-in {
  opacity: 1;
  transform: translateX(0);
}

.experience {
  margin: 0 10% 20px;
  background-color: #2c3e50;
  height: 100vh;
}
